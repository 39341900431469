import React from 'react';

// eslint-disable-next-line react/prop-types
export function Logo({ color = 'black' }) {
  return (
    <svg fill={color} width="24" height="30" viewBox="0 0 24 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.279 30V14.925L0.752351 14.4C0.476489 14.125 0.282131 13.8375 0.169279 13.5375C0.0564259 13.2375 0 12.9125 0 12.5625C0 12.2125 0.0564259 11.8812 0.169279 11.5687C0.282131 11.2562 0.476489 10.9625 0.752351 10.6875L5.5674 5.85H12.3386L18.2069 0L21.0282 2.8125C22.0063 3.7875 22.7398 4.90625 23.2288 6.16875C23.7179 7.43125 23.9749 8.7 24 9.975C23.9749 11.25 23.7179 12.5188 23.2288 13.7812C22.7398 15.0437 22.0063 16.1625 21.0282 17.1375L17.8307 20.325V30H1.279ZM3.53605 27.75H15.5737V19.3875L19.4107 15.5625C20.1881 14.7875 20.7712 13.9187 21.1599 12.9562C21.5486 11.9937 21.7429 11 21.7429 9.975C21.7429 8.95 21.5486 7.9625 21.1599 7.0125C20.7712 6.0625 20.1881 5.2 19.4107 4.425L18.2069 3.1875L13.279 8.1H6.50784L5.26646 9.3375L8.05016 12.1125C8.75235 12.8125 9.279 13.5938 9.63009 14.4562C9.98119 15.3187 10.1567 16.2125 10.1567 17.1375C10.1567 18.0125 10.0063 18.8625 9.70533 19.6875C9.40439 20.5125 8.94044 21.2625 8.31348 21.9375L3.53605 17.175V27.75Z" />
    </svg>
  );
}

export default Logo;
