/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import React from 'react';

// eslint-disable-next-line react/prop-types
export function LogoNew({ width }) {
  return (
    <svg
      width={width || '48.75px'}
      height="60"
      viewBox="0 0 285 352"
    >
      <path d="M109.754 351.501C206.587 351.229 285.001 272.647 285.001 175.751C285.001 78.8538 206.587 0.271719 109.754 0V39.2514C185.024 39.388 246.001 100.449 246.001 175.751C246.001 251.054 185.024 312.114 109.754 312.251V351.501Z" fill="#5981FA" />
      <path d="M47 118C47 104.193 58.1929 93 72 93C85.8071 93 97 104.193 97 118C97 131.807 85.8071 143 72 143C58.1929 143 47 131.807 47 118Z" fill="#5981FA" />
      <path d="M122 118C122 104.193 133.193 93 147 93C160.807 93 172 104.193 172 118C172 131.807 160.807 143 147 143C133.193 143 122 131.807 122 118Z" fill="#5981FA" />
      <path d="M132.809 177.815C120.891 164.558 100.109 164.558 88.1905 177.815L54.5027 215.286C40.0362 231.377 51.456 257 73.0939 257H147.906C169.544 257 180.964 231.377 166.497 215.286L132.809 177.815Z" fill="#5981FA" />
      <path d="M171 175C171 161.193 182.193 150 196 150C209.807 150 221 161.193 221 175C221 188.807 209.807 200 196 200C182.193 200 171 188.807 171 175Z" fill="#5981FA" />
      <path d="M0 175C0 161.193 11.1929 150 25 150C38.8071 150 50 161.193 50 175C50 188.807 38.8071 200 25 200C11.1929 200 0 188.807 0 175Z" fill="#5981FA" />
    </svg>
  );
}

export default LogoNew;
